import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { DataService } from '../../../services/data/data.service';

import { NotificationsService } from 'angular2-notifications';
import { RoomsService } from '../../../services/rooms/rooms.service';
import * as _ from 'lodash';
import { IRoom } from '../../../services/rooms/interfaces/room-interface.service';


@Component({
  selector: 'app-questions',
  templateUrl: './questions.component.html',
  styleUrls: ['./questions.component.scss']
})
export class QuestionsComponent implements OnInit {

  public playerStore = localStorage.getItem('playerStore');

  @Input() room: IRoom;
  @Input() round_cards: Array<object>;
  @Input() timerValue: number;
  @Input() questionId: number;

  @Output() finished: EventEmitter<string> = new EventEmitter();

  public gameForm: FormGroup;
  public answer0: FormControl;
  public answer1: FormControl;
  public answer2: FormControl;
  public answer3: FormControl;
  public answer4: FormControl;

  public showMore: Array<boolean> = [
    false,
    false,
    false,
    false,
    false,
  ];

  constructor(
    private _dataService: DataService,
    private _notificationsService: NotificationsService,
    private _roomsService: RoomsService,
  ) { }

  ngOnInit() {
    this.playerStore = (this.playerStore != null ? JSON.parse(this.playerStore) : { 'id': '', 'player': '' });
    this._buildForm();
  }

  get timer() {
    if (this.timerValue === 0) {
      this.save();
      this.timerValue = -1;
    }

    return this.timerValue;
  }

  setShowMore(i: number) {
    this.showMore[i] = true;
  }

  // FORM ---------------------------------------------
  _buildForm(): void {
    this.answer0 = new FormControl();
    this.answer1 = new FormControl();
    this.answer2 = new FormControl();
    this.answer3 = new FormControl();
    this.answer4 = new FormControl();

    this.gameForm = new FormGroup({
      'answer0': this.answer0,
      'answer1': this.answer1,
      'answer2': this.answer2,
      'answer3': this.answer3,
      'answer4': this.answer4,
    });
  }

  async onSubmit($event) {
    this.save();
    this.finished.emit('complete');
  }

  async save() {
    this._dataService.players[0].roundscore = 0;
    this._dataService.players[1].roundscore = 0;

    const answers = {
      'round': this._dataService.room.round,
      'player': this.playerStore['player'],
      'questionId': this.questionId,
      'answers': [
        this.answer0.value,
        this.answer1.value,
        this.answer2.value,
        this.answer3.value,
        this.answer4.value,
      ],
      'timerValue': (this.timerValue > 0 ? this.timerValue : 0)
    };

    this._roomsService.addAnswers(this.playerStore['id'], answers, this.playerStore['player'], this._dataService.room.round);
  }

  getAnswerValue(i: number) {
    let answer = '';
    switch (i) {
      case 0:
        answer = this.answer0.value;
        break;
      case 1:
        answer = this.answer1.value;
        break;
      case 2:
        answer = this.answer2.value;
        break;
      case 3:
        answer = this.answer3.value;
        break;
      case 4:
        answer = this.answer4.value;
        break;
    }
    return answer;
  }
}
