import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class AdsService {

  private amazonLinks: object = {
    "us": "https://www.amazon.com/"
  };

  constructor() { }

  genAmazonLink(productLink:string):string {
    return this.amazonLinks['us']+productLink;
  }
}
