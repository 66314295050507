import { Injectable } from '@angular/core';
import { FirestoreService } from '../../core/service/filestore.service';
import { AngularFirestoreDocument } from '@angular/fire/firestore';
import { ICategory } from './interfaces/category-interface.service';

@Injectable({
  providedIn: 'root'
})
export class CategoryService {

  constructor(
    private _firestoreService: FirestoreService
  ) { }

  get(category: string) {
    return new Promise((resolve, reject) => {
      this._firestoreService.colWithIds$('categories', ref => ref.where('category', '==', category))
        .subscribe((categories: Array<ICategory>) => {
          if (categories != null) {
            resolve(categories[0]);
          } else {
            reject('No category found for ' + category);
          }
        });
    });
  }

  async create(category: ICategory) {
    // check if category exsits
    return this.get(category.category).then((foundCategory: ICategory) => {
      // yes, add 1 to it's count
      foundCategory.message += ' ### ' + category.message;
      foundCategory.count++;
      return this.update(foundCategory);
    }).catch(err => {
      // no, create new record
      console.log('err: ', err);
      return this._firestoreService.add('categories', category);
    });
  }

  async update(category: ICategory) {
    const docRef: AngularFirestoreDocument<any> = this._firestoreService.col('categories').doc(category.id);
    return this._firestoreService.update(docRef, category);
  }
}
