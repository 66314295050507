import { environment } from '../environments/environment';

import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CoreModule } from './core/core.module';
import { HttpModule } from '@angular/http';
import { HttpClientModule } from '@angular/common/http';

// third party
import { SimpleNotificationsModule } from 'angular2-notifications';
import { WebStorageModule } from 'ngx-store';
import { ClipboardModule } from 'ngx-clipboard';
import { FlexLayoutModule } from '@angular/flex-layout';
import { ReCaptchaModule } from 'angular2-recaptcha';
import {
  MatFormFieldModule,
  MatButtonModule,
  MatRadioModule,
  MatInputModule,
  MatProgressSpinnerModule,
  MatTabsModule,
  MatStepperModule
} from '@angular/material';

// FireMod
import { AngularFireModule } from '@angular/fire';
import { AngularFirestoreModule } from '@angular/fire/firestore';
import { AngularFireAuthModule } from '@angular/fire/auth';

// shared
import { SharedUiModule } from './components/shared-ui/shared-ui.module';

// pages
import { HomeComponent } from './components/home/home.component';
import { TermsComponent } from './components/terms/terms.component';
import { GameComponent } from './components/game/game.component';
import { HowItWorksComponent } from './components/how-it-works/how-it-works.component';
import { RoomStartComponent } from './components/room-start/room-start.component';
import { RoomJoinComponent } from './components/room-join/room-join.component';
import { RoomLobbyComponent } from './components/room-lobby/room-lobby.component';
import { QuestionsComponent } from './components/game/questions/questions.component';

// services
import { DataService } from './services/data/data.service';
import { RoomsService } from './services/rooms/rooms.service';
import { QuestionsService } from './services/questions/questions.service';
import { EndComponent } from './components/end/end.component';
import { CategoriesComponent } from './components/categories/categories.component';
import { ContactComponent } from './components/contact/contact.component';
import { AdsService } from './services/ads/ads.service';
import { ExceededLimitComponent } from './components/exceeded-limit/exceeded-limit.component';
import { NotFoundComponent } from './components/not-found/not-found.component';

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    TermsComponent,
    GameComponent,
    HowItWorksComponent,
    RoomStartComponent,
    RoomJoinComponent,
    RoomLobbyComponent,
    QuestionsComponent,
    EndComponent,
    CategoriesComponent,
    ContactComponent,
    ExceededLimitComponent,
    NotFoundComponent
  ],
  imports: [
    CoreModule,
    FormsModule,
    ReactiveFormsModule,
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    SharedUiModule,
    HttpModule,
    HttpClientModule,

    // Thrird party
    SimpleNotificationsModule.forRoot(),
    FlexLayoutModule,
    ClipboardModule,
    WebStorageModule,
    ReCaptchaModule,
    BrowserAnimationsModule,

    // materiaal
    MatFormFieldModule,
    MatButtonModule,
    MatRadioModule,
    MatInputModule,
    MatProgressSpinnerModule,
    MatTabsModule,
    MatStepperModule,

    // Start Firebase
    AngularFireModule.initializeApp(environment.firebase),
    AngularFirestoreModule,
    AngularFireAuthModule
  ],
  providers: [
    DataService,
    RoomsService,
    QuestionsService,
    AdsService
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
