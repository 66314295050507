import { Injectable } from '@angular/core';
import { DataService } from '../data/data.service';
import { FirestoreService } from '../../core/service/filestore.service';
import { AngularFirestore, AngularFirestoreDocument } from '@angular/fire/firestore';
import { IRoom } from './interfaces/room-interface.service';
import { Observable, Observer } from 'rxjs';
import { IPlayer } from './interfaces/player-interface.service';
import * as _ from 'lodash';

@Injectable()
export class RoomsService {

  private scoresCheckedForRound: number = -1;

  constructor(
    private _dataService: DataService,
    private _firestoreService: FirestoreService,
    private db: AngularFirestore
  ) { }

  async get(docId: string): Promise<object> {
    return new Promise((resolve, reject) => {
      this._firestoreService.doc$('rooms/' + docId).subscribe((room: IRoom) => {
        if (room !== undefined) {
          // load players in
          this.playerGetAll(docId).then((players: Array<IPlayer>) => {
            this._dataService.players = players;
            this._dataService.room = room;
            resolve(room);
          }).catch(err => {
            reject(err);
          });
        } else {
          reject("No Room found: '" + docId + "', Please check your code and try again");
        }
      }, err => {
        // throw new Error(err);
        reject(err);
      });
    });
  }

  async getInRealTime(docId: string) {
    const room = this._firestoreService.doc('rooms/' + docId);
    room.valueChanges().subscribe((r: IRoom) => {
      this._dataService.room = r;
    }, err => {
      throw new Error(err);
    });
  }

  async create(room: IRoom) {
    const newRoom = await this._firestoreService.add('rooms', room);
    room.uid = newRoom.id;

    this._dataService.room = room;
    return room;
  }

  async update(room: IRoom, docId: string) {
    const docRef: AngularFirestoreDocument<any> = this._firestoreService.col('rooms').doc(docId);
    return this._firestoreService.update(docRef, room);
  }


  // ANSWERS ------------------------------------------------------------------------------
  async deleteAnswer(roomId: string, docId: string) {
    return this._firestoreService.delete(this._firestoreService.col('rooms/' + roomId + '/answers').doc(docId));
  }

  async close(roomId: string) {
    this.get(roomId).then((room: IRoom) => {
      room.finished = true;
      room.connected = false;
      this.update(room, roomId);
    }).catch(err => {
      throw new Error(err);
    });
  }

  getAnswersToDelete(roomId: string): Promise<Array<object>> {
    // console.log("deleteAnswersForNextRound: " + roomId);
    return new Promise((resolve, reject) => {
      this._firestoreService.colWithIds$(`rooms/${roomId}/answers`).subscribe((answers: Array<object>) => {
        resolve(answers);
      });
    });
  }

  getAnswersByRound(roomId: string, round: number, player: number) {
    return new Promise((resolve, reject) => {
      // load up
      this._firestoreService.col$(`rooms/${roomId}/answers`, ref => ref.where('round', '==', round)).subscribe(roundAnswers => {
        // Work out SCORES when both scores are available
        if (roundAnswers.length == 2) {
          // console.log('Both ANSWERS are in...');
          this._dataService.showScores = true;

          const updateRoom = this._dataService.room;

          const breakDown = { 'match': 0, 'null': 0, 'maybe': 0, 'missmatch': 0 }

          // MULTI SCORE
          // null = -2
          // not a match = -1
          // match:
          // exsact match = +2
          // sometimes with a yes = +1
          // Bonus points: When guessing your partners likes, if it matches with a previous like, give em a point!
          let player1roundscore = 0;
          let player2roundscore = 0;

          // add timer score
          player1roundscore += roundAnswers[0]['timerValue'];
          // console.log('player1 time bounus', player1roundscore);

          player2roundscore += roundAnswers[1]['timerValue'];
          // console.log('player2 time bounus', player2roundscore);

          for (let i = 0; i < 5; i++) {
            // catch non answered questions
            if (roundAnswers[0]['answers'][i] == null) {
              // console.log('player1 didn\'t answer Q' + i);
              // this._dataService.players[roundAnswers[0]['player']-1].roundscore -= 2;
              player1roundscore -= 2;
              // console.log(roundAnswers[0]['player']-1+" null: -2")
              breakDown['null']++;
            }
            if (roundAnswers[1]['answers'][i] == null) {
              // console.log('player2 didn\'t answer Q' + i);
              // this._dataService.players[roundAnswers[1]['player']-1].roundscore -= 2;
              player2roundscore -= 2;
              // console.log(roundAnswers[0]['player']-1+" null: -2")
              breakDown['null']++;
            }
            // catch matches
            if (roundAnswers[0]['answers'][i] != null && roundAnswers[1]['answers'][i] != null) {
              // exact match
              if (roundAnswers[0]['answers'][i] == roundAnswers[1]['answers'][i]) {
                // console.log('MATCH '+i, roundAnswers[0]['answers'][i]+' == '+roundAnswers[1]['answers'][i])

                player1roundscore += 6;
                player2roundscore += 6;
                // this._dataService.players[0].roundscore++; // SIMPLE SCORING
                // this._dataService.players[1].roundscore++; // SIMPLE SCORING
                breakDown['match']++;
                // console.log("+2")
                // sometimes with a yes
              } else if (roundAnswers[0]['answers'][i] == "m" && roundAnswers[1]['answers'][i] == 'y' || roundAnswers[0]['answers'][i] == "y" && roundAnswers[1]['answers'][i] == 'm') {
                player1roundscore += 3;
                player2roundscore += 3;
                breakDown['maybe']++;
                // console.log("+1")
              } else {
                // player1roundscore--;
                // player2roundscore--;
                breakDown['missmatch']++;
                // console.log("-1")
              }
            }

            // look up questions that match on "what does your partner like" - eg: player 1 likes anal and player2 things player 1 likes = points!
            // if(round % 2 === 1 && round > 1) {
            //   console.log("check for past matches");
            //   // get previous rounds which are "I like" (odd)
            //   _.values(this._dataService.room.cards).forEach((cards, round) => {
            //     if(round % 2 == 1) {
            //       // loop through them and look for matches
            //       cards.forEach(element => {
            //         if()
            //         // add points
            //       });


            //     }
            //   });
            // }
            // console.log('player1 score:', player1roundscore);
            // console.log('player2 score:', player2roundscore);

            // add answers to items for end tally up
            updateRoom.cards[round][i]['player' + roundAnswers[0]['player']] = roundAnswers[0]['answers'][i];
            updateRoom.cards[round][i]['player' + roundAnswers[1]['player']] = roundAnswers[1]['answers'][i];
            updateRoom.cards[round][i]['questionId'] = roundAnswers[0]['questionId'];
          }

          // add score to answers object for "last round"
          roundAnswers[0]['score'] = player1roundscore;
          roundAnswers[1]['score'] = player2roundscore;

          if (typeof updateRoom.roundScores == 'undefined') {
            updateRoom.roundScores = [];
          }
          if (typeof updateRoom.roundScoresPlayer1 == 'undefined') {
            updateRoom.roundScoresPlayer1 = [];
          }
          if (typeof updateRoom.roundScoresPlayer2 == 'undefined') {
            updateRoom.roundScoresPlayer2 = [];
          }
          updateRoom.roundScores[round] = player1roundscore;
          updateRoom.roundScoresPlayer1[round] = player1roundscore;
          updateRoom.roundScoresPlayer2[round] = player2roundscore;

          // console.log("Playyyyya: ", this._dataService.players);

          // SAVE IT - once
          if (player === 1) {
            try {
              this.update(updateRoom, roomId);
            } catch (err) {
              console.log(err);
            }
          }
          resolve(breakDown);
        }
      });
    });
  }

  async addAnswers(roomId: string, answers: object, playerNo: number, round: number) {
    await this._firestoreService.upsert(`rooms/${roomId}/answers/round${round}player${playerNo}`, answers);
  }

  // PLAYERS ------------------------------------------------------------------------------
  async playerGetAll(roomId: string) {
    return new Promise((resolve, reject) => {
      this._firestoreService.colWithIds$('rooms/' + roomId + '/players/', ref => ref.orderBy('uid', 'asc'))
      .subscribe((players: Array<IPlayer>) => {
        if (players) {
          resolve(players);
        } else {
          reject("no players found")
        }
      });
    });
  }

  async getPlayerByUid(roomId: string, playerUid: string) {
    return new Promise((resolve, reject) => {
      this.playerGetAll(roomId).then((players: Array<object>) => {
        players.forEach((player: IPlayer) => {
          if (player.uid == playerUid) {
            resolve(player);
          }
        });
        reject("no player found with the uid " + playerUid);
      }).catch(err => console.log('ERROR', err));
    });
  }

  async playerGet(roomId: string, playerId: string, index: number) {
    let player = this._firestoreService.doc(`rooms/${roomId}/players/${playerId}`);
    player.valueChanges().subscribe((p: IPlayer) => {
      if (typeof p != 'undefined') {
        p['id'] = playerId;
        this._dataService.players[index] = p;
      }
    });
  }

  async playerCreate(roomId: string, player: IPlayer) {
    return this._firestoreService.add(`rooms/${roomId}/players/`, player);
  }

  async playerUpdate(roomId: string, docId: string, player: IPlayer) {
    const docRef: AngularFirestoreDocument<any> = this._firestoreService.doc(`rooms/${roomId}/players/${docId}`);
    return this._firestoreService.update(docRef, player);
  }
}
