import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { HeaderComponent } from './header/header.component';
import { FooterComponent } from './footer/footer.component';
import { GameHeaderComponent } from './game-header/game-header.component';
import { FooterEndComponent } from './footer-end/footer-end.component';
import { ClipboardModule } from 'ngx-clipboard';


@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    FlexLayoutModule,
    ClipboardModule,
  ],
  declarations: [
    HeaderComponent,
    FooterComponent,
    GameHeaderComponent,
    FooterEndComponent,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  exports: [
    HeaderComponent,
    FooterComponent,
    GameHeaderComponent,
    FooterEndComponent,
  ]
})
export class SharedUiModule { }
