import { Component, OnInit } from '@angular/core';
import { QuestionsService } from '../../services/questions/questions.service';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { CategoryService } from '../../services/category/category.service';
import { NotificationsService } from 'angular2-notifications';
import { ICategory } from '../../services/category/interfaces/category-interface.service';
import { Router } from '@angular/router';

declare let ga: Function;

@Component({
  selector: 'app-categories',
  templateUrl: './categories.component.html',
  styleUrls: ['./categories.component.scss']
})
export class CategoriesComponent implements OnInit {

  public mfCards: Array<object> = [];
  public mmCards: Array<object> = [];
  public ffCards: Array<object> = [];

  public categoryForm: FormGroup;
  public category: FormControl;
  public message: FormControl;

  public isWorking = false;
  public objectKeys = Object.keys;

  // public displayCategory: string = 'mf';

  constructor(
    private _questionsService: QuestionsService,
    private _categoryService: CategoryService,
    private _notificationsService: NotificationsService,
    private _router: Router
  ) { }

  ngOnInit() {
    this._questionsService.getAll('mf')
      .then(cards => this.mfCards = cards.sort((a, b) => (a['title'] < b['title'] ? -1 : (a['title'] > b['title'] ? 1 : 0))))
      .catch(err => {
        if (err.message === 'FirebaseError: [code=resource-exhausted]: Quota exceeded.' || err.message === 'Quota exceeded.') {
          this._router.navigate(['/overloaded']);
        }
    });

    this._questionsService.getAll('mm')
      .then(cards => this.mmCards = cards.sort((a, b) => (a['title'] < b['title'] ? -1 : (a['title'] > b['title'] ? 1 : 0)))).catch(err => {
        // console.log('err', err, typeof err);
      });

    this._questionsService.getAll('ff')
      .then(cards => this.ffCards = cards.sort((a, b) => (a['title'] < b['title'] ? -1 : (a['title'] > b['title'] ? 1 : 0)))).catch(err => {
        // console.log('err', err, typeof err);
      });

    this._buildForm();
  }

  // FORM ---------------------------------------------
  _buildForm(): void {

    this.category = new FormControl('', [Validators.required, Validators.minLength(2)]);
    this.message = new FormControl();

    this.categoryForm = new FormGroup({
      'category': this.category,
      'message': this.message
    });
  }

  async onSubmit($event) {
    $event.preventDefault();
    this.disableForm();

    this.isWorking = true;

    const vo = {} as ICategory;
    vo.category = this.category.value;
    vo.message = this.message.value;
    vo.count = 1;

     // update planData
     try {
      this._categoryService.create(vo).then(() => {
        this._notificationsService.create('Success', 'You have successfully submitted a category!');
        this.isWorking = false;
        this.categoryForm.reset();
        this.enableForm();
      }).catch(err => {
        this._notificationsService.error('Error', err);
      });
    } catch (err) {
      this.isWorking = false;
      console.log(err);
      this._notificationsService.error('Error:', err.message);
    }
  }

  disableForm() {
    this.category.disable();
    this.message.disable();
  }

  enableForm() {
    this.category.enable();
    this.message.enable();
  }

  // ads
  isDefined(val): boolean {
    return typeof val !== 'undefined';
  }

  genAmazonLink(val: string): string {
    return val;
    // return this._adsService.genAmazonLink(val);
  }

  affilateTracking(type: string, item: string) {
    // ga('send', 'event', 'affilate', type, item);
  }
}
