import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { DataService } from '../../services/data/data.service';
import { IRoom } from '../../services/rooms/interfaces/room-interface.service';
import { RoomsService } from '../../services/rooms/rooms.service';
import { NotificationsService } from 'angular2-notifications';
import * as _ from 'lodash';
import { IPlayer } from '../../services/rooms/interfaces/player-interface.service';

import { QuestionsService } from '../../services/questions/questions.service';

@Component({
  selector: 'app-room-join',
  templateUrl: './room-join.component.html',
  styleUrls: ['./room-join.component.scss']
})
export class RoomJoinComponent implements OnInit {

  public playerStore = localStorage.getItem('playerStore');

  public room = {} as IRoom;

  public joinForm: FormGroup;
  public code: FormControl;
  public nickname: FormControl;
  public gender: FormControl;

  public isWorking = false;

  constructor(
    private _activatedRouter: ActivatedRoute,
    private _dataService: DataService,
    private _roomsService: RoomsService,
    private _notificationsService: NotificationsService,
    private _questionsService: QuestionsService,
    private _router: Router
  ) { }

  ngOnInit() {
    this.playerStore = (this.playerStore !== null ? JSON.parse(this.playerStore) : {});
    this._buildForm();

    // check for running game
    if (typeof this.playerStore['id'] !== 'undefined') {
      this._roomsService.get(this.playerStore['id']).then((room: IRoom) => {
        this.room = room;

        // Reset the cookie if game is closed
        // if(room.finished == true) {
        //   localStorage.setItem('playerStore', JSON.stringify({}))
        // }
      }).catch(err => {
        console.log(err);
        localStorage.setItem('playerStore', JSON.stringify({}));
      });
    }

    // auto enter code if in url
    this._activatedRouter.params.subscribe(params => {
      if (typeof params['c'] !== 'undefined') {
        if (this.joinForm.pristine) {
          this.joinForm.patchValue({
            'code': params['c'].trim(),
          });
        }
      }
    });
  }

  get roomId() {
    return this.playerStore['id'];
  }

  get realtimePlayer1() {
    return typeof this._dataService.players[0] !== 'undefined' ? this._dataService.players[0] : {} as IPlayer;
  }

  get realtimePlayer2() {
    return typeof this._dataService.players[1] !== 'undefined' ? this._dataService.players[1] : {} as IPlayer;
  }

  // FORM ---------------------------------------------
  _buildForm(): void {

    this.code = new FormControl('', [Validators.required]);
    this.nickname = new FormControl('', [Validators.required, Validators.minLength(2)]);
    this.gender = new FormControl('', [Validators.required]);

    this.joinForm = new FormGroup({
      'code': this.code,
      'nickname': this.nickname,
      'gender': this.gender,
    });
  }

  async onSubmit($event) {
    $event.preventDefault();
    this.save();
  }

  async save() {
    this.isWorking = true;

    this._roomsService.get(this.code.value.trim()).then((room: IRoom) => {
      // check if room is full - can only connect once
      if (room.connected) {
        this._notificationsService.error('Error:', 'Sorry, room is full - you might need to create a new one');
        return false;
      }

      // load temp player
      const player2: IPlayer = this._dataService.players[1];
      player2.uid = 'player2';
      player2.nickname = this.nickname.value;
      player2.gender = this.gender.value;
      player2.score = 0;
      player2.ready = false;
      this._dataService.players[1] = player2;
      // this._dataService.players.push(player2);

      // set the combo
      this._dataService.room.combo = 'mf';
      if (this._dataService.players[0].gender === 'Male' && player2.gender === 'Male') {
        this._dataService.room.combo = 'mm';
      } else if (this._dataService.players[0].gender === 'Female' && player2.gender === 'Female') {
        this._dataService.room.combo = 'ff';
      }

      // add genders to the room it self for easy querying
      this._dataService.room.player1 = { gender: this._dataService.players[0].gender, nickname: this._dataService.players[0].nickname };
      this._dataService.room.player2 = { gender: this._dataService.players[1].gender, nickname: this._dataService.players[1].nickname };

      // catch test rooms
      if (this._dataService.players[0].nickname === 'testing') {
        this._dataService.room.test = true;
      }

      // lock the room
      this._dataService.room.connected = true;

      // store the user
      localStorage.setItem('playerStore', JSON.stringify({ 'id': this.code.value.trim(), 'player': 2 }));

      // GENERATE GAME CARDS
      // get cards for this round
      const pickedQuestions: Array<string> = [];
      const gameCards: object = {};
      let round = 0;
      this._questionsService.getAll().then((cards: Array<object>) => {
        // go through each round, pulling random cards to build up the whole game
        // limit per round
        this._dataService.escalation_rules[this._dataService.room.combo].forEach(allowed => {
          const cardsToChooseFrom = [];
          // loop all cards
          cards.forEach(item => {
            // correct category
            if (allowed.indexOf(item['category']) !== -1) {
              // question is allowed for combo
              if (item['combo'].length === 0 || item['combo'].indexOf(this._dataService.room.combo) !== -1) {
                // make sure question is unique
                if (pickedQuestions.indexOf(item['image']) === -1) {
                  cardsToChooseFrom.push(item);
                }
              }
            }
          });
          const round_cards = this.getRandomElementsFromArray(cardsToChooseFrom, 5);
          // make sure question is unique
          round_cards.forEach(element => {
            pickedQuestions.push(element['image']);
          });

          gameCards[round] = round_cards;
          round++;
        });
        this._dataService.room.cards = gameCards;

        // update planData
        try {
          this._roomsService.update(this._dataService.room, this.code.value.trim());

          this._roomsService.playerUpdate(this.code.value.trim(), player2.id, player2);
          //  this._roomsService.playerCreate(this.code.value.trim(), player2);
          this._notificationsService.create('Success', 'You have successfully joined a game!');
          this._router.navigate(['/game', { 'c': this.code.value.trim() }]);
        } catch (err) {
          this.isWorking = false;
          console.log(err);

          if (_.has(err, 'code')) {
            this._notificationsService.error('Error:', err.message);
          }
        }
      });

    }).catch(err => {
      this._notificationsService.error('Error:', err);
      this.isWorking = false;
    });
  }

  getRandomElementsFromArray(arr, n) {
    const result = new Array(n);
    let len = arr.length;
    const taken = new Array(len);
    if (n > len) {
      throw new RangeError('getRandom: more elements taken than available');
    }
    while (n--) {
      const x = Math.floor(Math.random() * len);
      result[n] = arr[x in taken ? taken[x] : x];
      taken[x] = --len in taken ? taken[len] : len;
    }
    return result;
  }

}
