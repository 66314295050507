import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { HomeComponent } from './components/home/home.component';
import { TermsComponent } from './components/terms/terms.component';
import { HowItWorksComponent } from './components/how-it-works/how-it-works.component';
import { RoomStartComponent } from './components/room-start/room-start.component';
import { RoomJoinComponent } from './components/room-join/room-join.component';
import { RoomLobbyComponent } from './components/room-lobby/room-lobby.component';
import { GameComponent } from './components/game/game.component';
import { EndComponent } from './components/end/end.component';
import { CategoriesComponent } from './components/categories/categories.component';
import { ContactComponent } from './components/contact/contact.component';
import { ExceededLimitComponent } from './components/exceeded-limit/exceeded-limit.component';
import { NotFoundComponent } from './components/not-found/not-found.component';


@NgModule({
  imports: [
    RouterModule.forRoot([

      /*
        define app module routes here, e.g., to lazily load a module
        (do not place feature module routes here, use an own -routing.module.ts in the feature instead)
      */
      {
        path: 'home',
        component: HomeComponent
      },
      {
        path: 'how-to-play',
        component: HowItWorksComponent
      },
      {
        path: 'terms',
        component: TermsComponent
      },
      {
        path: 'questions',
        component: CategoriesComponent
      },
      {
        path: 'contact',
        component: ContactComponent
      },

      {
        path: 'start',
        component: RoomStartComponent
      },
      {
        path: 'lobby/:c',
        component: RoomLobbyComponent
      },
      {
        path: 'join/:c',
        component: RoomJoinComponent
      },
      {
        path: 'join',
        component: RoomJoinComponent
      },
      {
        path: 'game',
        component: GameComponent
      },
      {
        path: 'end',
        component: EndComponent
      },
      {
        path: 'overloaded',
        component: ExceededLimitComponent
      },

      // {
      //   path: 'game',
      //   loadChildren: 'app/components/game/game.module'
      // },

      // {
      //   path: 'partners',
      //   loadChildren: 'app/components/partners/partners.module#PartnersModule'
      // },
      // {
      //   path: '',
      //   redirectTo: '/',
      //   pathMatch: 'full'
      // },

      /* define app module routes here, e.g., to lazily load a module
       (do not place feature module routes here, use an own -routing.module.ts in the feature instead)
       */
      { path: '', component: HomeComponent },
      // { path: 'callback', component: CallbackComponent },
      // { path: '**', redirectTo: '' }
      { path: '404', component: NotFoundComponent },
      { path: '**', redirectTo: '404' }
    ])
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
