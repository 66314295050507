import { Component, OnInit, DoCheck } from '@angular/core';
import { DataService } from '../../services/data/data.service';
import { QuestionsService } from '../../services/questions/questions.service';
import { RoomsService } from '../../services/rooms/rooms.service';
import { IRoom } from '../../services/rooms/interfaces/room-interface.service';
import { Router } from '@angular/router';
import { NotificationsService } from 'angular2-notifications';
import * as _ from 'lodash';
import { IPlayer } from '../../services/rooms/interfaces/player-interface.service';

@Component({
  selector: 'app-game',
  templateUrl: './game.component.html',
  styleUrls: ['./game.component.scss']
})
export class GameComponent implements OnInit, DoCheck {

  public playerStore = localStorage.getItem('playerStore');

  public room: IRoom;
  public round_cards: Array<object>;

  public timerSetValue: number = 40;
  public timerValue: number = this.timerSetValue;
  private timer: any;

  public screen: string = 'instructions';
  public waiting: boolean = false;
  public warning: boolean = false;
  public live: boolean = false;
  private dataHasLoaded: boolean = false;

  public question: number = 0; // random between 0-1
  public roundQuestions: Array<string> = [];
  public stats: object;

  public scoreLastRound: number = 0;
  public thisRoundScore: number = 0;

  public scores: Array<object> = []; // player 1 / 2
  public scoresLast: Array<object> = []; // player 1 / 2

  public showHowToPlay: boolean = true;

  constructor(
    private _dataService: DataService,
    private _questionsService: QuestionsService,
    private _roomsService: RoomsService,
    private _router: Router,
    private _notificationsService: NotificationsService,
  ) {
    document.body.className = '';
  }

  ngOnInit() {
    if (this.playerStore == null) {
      this._notificationsService.error('Error:', 'Room ID not found. Please try again');
      this._router.navigate(['/']);
    }
    this.playerStore = (this.playerStore != null ? JSON.parse(this.playerStore) : { 'id': '', 'player': '' });

    // check for running game
    if (this.playerStore['id'] !== '') {
      this._roomsService.get(this.playerStore['id']).then((room: IRoom) => {
        if (typeof room !== 'undefined') {
          this.room = room;

          // check game hasn't finished
          if (this._dataService.room.round >= 15) {
            this._notificationsService.error('Error:', 'Game has ended');
            this._router.navigate(['/end']);
          }

          if (this._dataService.room.round > 0) {
            this.showHowToPlay = false;
          }

          // load up cards for the round
          this.loadRoundCards();

          // load it in read time for shared play
          if (!this.live) {
            this._roomsService.getInRealTime(this.playerStore['id']);
            this._roomsService.playerGet(this.playerStore['id'], this._dataService.players[0]['id'], 0);
            this._roomsService.playerGet(this.playerStore['id'], this._dataService.players[1]['id'], 1);
            this.live = true;
          }
        }
      }).catch(() => {
        // no jumping in on games
        if (typeof this.room.connected === 'undefined') {
          this._notificationsService.error('Error:', 'Room id not found, please check it and try again');
          this._router.navigate(['/join']);
        }
      });
    } else {
      this._notificationsService.error('Error:', 'Room id not found, please check it and try again');
      this._router.navigate(['/join']);
    }

    // round questions
    this.roundQuestions = this._dataService.RoundQuestions;
  }

  ngDoCheck() {
    if (typeof this._dataService.room.round !== 'undefined' && !this.dataHasLoaded) {
      this.question = this._dataService.room.round % this._dataService.RoundQuestions.length;
      this.dataHasLoaded = true;
    }
  }

  get realtimePlayer1() {
    return typeof this._dataService.players[0] !== 'undefined' ? this._dataService.players[0] : {} as IPlayer;
  }

  get realtimePlayer2() {
    return typeof this._dataService.players[1] !== 'undefined' ? this._dataService.players[1] : {} as IPlayer;
  }

  get realtimeRoom() {
    // catch if room gets closed
    if (this._dataService.room.finished === true && this.warning === false) {
      this._notificationsService.warn('Game over', 'Review your matches!');
      this._router.navigate(['/end']);
      this.warning = true;
    }

    // catch ready triggers
    if (typeof this._dataService.players[0] !== 'undefined'
      && typeof this._dataService.players[1] !== 'undefined'
      && this._dataService.players[0].ready === true
      && this._dataService.players[1].ready === true
      && this.screen === 'instructions') {
      this.startRound();
    }

    return this._dataService.room;
  }

  get showScores() {
    return this._dataService.showScores;
  }

  get scoreP1() {
    if (typeof this._dataService.room !== 'undefined' && typeof this._dataService.room.roundScoresPlayer1 !== 'undefined') {
      return this._dataService.room.roundScoresPlayer1.reduce((a, b) => a + b, 0);
    }
    return 0;
  }

  get scoreP2() {
    if (typeof this._dataService.room !== 'undefined' && typeof this._dataService.room.roundScoresPlayer2 !== 'undefined') {
      return this._dataService.room.roundScoresPlayer2.reduce((a, b) => a + b, 0);
    }
    return 0;
  }

  get score() {
    if (typeof this._dataService.room !== 'undefined' && typeof this._dataService.room.roundScores !== 'undefined') {
      return this._dataService.room.roundScores.reduce((a, b) => a + b, 0);
    }
    return 0;
  }

  get getRoundScore() {
    return this.thisRoundScore;
  }

  // SCORES ---------------------------------------------
  roundScore() {
    // console.log("ROUND: ", this._dataService.room.round);

    // single score
    this.thisRoundScore = this._dataService.room.roundScores[this._dataService.room.round];
    this.scoreLastRound = 0;
    if (this._dataService.room.round - 1 >= 0) {
      const lastroundscores = this._dataService.room.roundScores;
      lastroundscores.splice(lastroundscores.length - 1, 1);
      // console.log('lastroundscores', lastroundscores);
      this.scoreLastRound = this._dataService.room.roundScores.reduce((a, b) => a + b, 0);
    }

    // two scores
    this.scores['player1'] = this._dataService.room.roundScoresPlayer1[this._dataService.room.round];
    this.scores['player2'] = this._dataService.room.roundScoresPlayer2[this._dataService.room.round];
    this.scoresLast['player1'] = 0;
    this.scoresLast['player2'] = 0;
    if (this._dataService.room.round - 1 >= 0) {
      const lastroundscores1 = this._dataService.room.roundScoresPlayer1;
      const lastroundscores2 = this._dataService.room.roundScoresPlayer2;
      lastroundscores1.splice(lastroundscores1.length - 1, 1);
      lastroundscores2.splice(lastroundscores2.length - 1, 1);
      // console.log('lastroundscores1', lastroundscores1);
      // console.log('lastroundscores2', lastroundscores2);
      this.scoresLast['player1'] = this._dataService.room.roundScoresPlayer1.reduce((a, b) => a + b, 0);
      this.scoresLast['player2'] = this._dataService.room.roundScoresPlayer2.reduce((a, b) => a + b, 0);
    }

  }

  // TIMER ---------------------------------------------
  startTimer() {
    // const timeout: number = -Math.abs(this.timerValue);
    this.timer = setInterval(() => {
      this.timerValue--;

      if (this.timerValue <= -1) {
        this.finsihRound();
        clearInterval(this.timer);
      }
    }, 1000);
  }

  // ROUND (SHOW SCORES) ---------------------------------------------
  async finsihRound() {
    // console.log('PLAYER ' + this.playerStore['player'] + ' FINSIHED ROUND');
    clearInterval(this.timer);

    this.screen = 'score';
    // this.waiting = true;

    await this._roomsService.getAnswersByRound(this.playerStore['id'], this._dataService.room.round, this.playerStore['player'])
      .then(stats => {
        this.stats = stats;
        this.roundScore();
      })
      .catch(err => console.log('getAnswersByRound ERROR:', err));

    // next round
    if (this.playerStore['player'] === 1) {
      const roomVo = {} as IRoom;
      roomVo.round = this._dataService.room.round + 1;
      this.save(roomVo);
    }

    // save answers to DB
    // this.save();
  }

  async nextRound() {
    this.screen = 'instructions';

    // timer gets quicker as you play
    this.timerValue = this.timerSetValue - Math.floor(this._dataService.room.round / 4);
    // console.log("timerValue", this.timerValue);

    // make sure round is the next one and not missed the update from the server
    if (this._dataService.lastRound === this._dataService.room.round) {
      const roomVO = {} as IRoom;
      roomVO.round = this._dataService.room.round + 1;
      await this._roomsService.update(roomVO, this.playerStore['id']).then(() => {
        this._roomsService.get(this.playerStore['id']);
      });
    }
    this._dataService.lastRound = this._dataService.room.round;

    await this.loadRoundCards();

    // instructional message
    this.question = this._dataService.room.round % this._dataService.RoundQuestions.length;

    // body class
    document.body.className = this.question === 0 ? '' : 'bg-blue';
  }

  startRound() {
    this.waiting = false;
    this.screen = 'game';
    this._dataService.showScores = false;
    this.startTimer();
    this.resetPlayers();
  }

  async loadRoundCards() {
    if (this._dataService.room.round < 0) {
      const roomVO = {} as IRoom;
      roomVO.round = 0;
      await this._roomsService.update(roomVO, this.playerStore['id']).then(() => {
        this.round_cards = this._dataService.room.cards[0];
      });
    } else {
      this.round_cards = this._dataService.room.cards[this._dataService.room.round];
    }
  }

  endGame() {
    if (this._dataService.room.round >= 0) {
      const roomVo = {} as IRoom;
      roomVo.finished = true;
      roomVo.round = -1;
      this._roomsService.update(roomVo, this.playerStore['id']).then(() => {
        this._router.navigate(['/end']);
      });
    }
  }

  // PLAYER STATUS ---------------------------------------------
  playerReady() {
    this.waiting = true;
    const playervo = {} as IPlayer;
    playervo.id = this._dataService.players[this.playerStore['player'] - 1].id;
    playervo.ready = true;
    this.savePlayer(playervo);
  }

  resetPlayers() {
    const playervo1 = {} as IPlayer;
    playervo1.id = this._dataService.players[0].id;
    playervo1.ready = false;
    this.savePlayer(playervo1);
    const playervo2 = {} as IPlayer;
    playervo2.id = this._dataService.players[1].id;
    playervo2.ready = false;
    this.savePlayer(playervo2);
  }

  // DB ---------------------------------------------
  save(roomVo: IRoom) {
    // update DB
    // update planData
    try {
      this._roomsService.update(roomVo, this.playerStore['id']);
    } catch (err) {
      console.log(err);

      if (_.has(err, 'code')) {
        this._notificationsService.error('Error:', err.message);
      }
    }
  }

  savePlayer(player: IPlayer) {
    // update DB
    // update planData
    try {
      this._roomsService.playerUpdate(this.playerStore['id'], player.id, player);
    } catch (err) {
      console.log(err);
      this.waiting = false;
      if (_.has(err, 'code')) {
        this._notificationsService.error('Error:', err.message);
      }
    }
  }

  isThisNaN(str) {
    return isNaN(str);
  }
}
