import { Component, OnInit } from '@angular/core';
import { IRoom } from '../../services/rooms/interfaces/room-interface.service';
import { RoomsService } from '../../services/rooms/rooms.service';
import { DataService } from '../../services/data/data.service';
import { IPlayer } from '../../services/rooms/interfaces/player-interface.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

  public playerStore = localStorage.getItem('playerStore');
  public bg: any = 'bg';
  private bgs: Array<string> = ['bg', 'bg2', 'bg3'];

  constructor(
    private _roomsService: RoomsService,
    private _dataService: DataService,
    private _router: Router
  ) {
    document.body.className = '';
  }

  ngOnInit() {
    this.playerStore = (this.playerStore != null ? JSON.parse(this.playerStore) : {});

    // check for running game
    if (typeof this.playerStore['id'] !== 'undefined') {
      this._roomsService.get(this.playerStore['id']).then((room: IRoom) => {
        this._roomsService.getInRealTime(this.playerStore['id']);

        // Reset the cookie if game is closed
        // if(room.finished == true) {
        //   localStorage.setItem('playerStore', JSON.stringify({}))
        // }

      }).catch(err => {
        console.error('err', err);

        // catch over quota
        if (err.status === '') {
          this._router.navigate(['/overloaded']);
        }

        localStorage.setItem('playerStore', JSON.stringify({}));
      });
    }

    this.loadBg();
  }

  get roomId() {
    return this.playerStore['id'];
  }

  get room() {
    return typeof this._dataService.room !== 'undefined' ? this._dataService.room : {} as IRoom;
  }

  get realtimePlayer1() {
    return typeof this._dataService.players[0] !== 'undefined' ? this._dataService.players[0] : {} as IPlayer;
  }

  get realtimePlayer2() {
    return typeof this._dataService.players[1] !== 'undefined' ? this._dataService.players[1] : {} as IPlayer;
  }

  loadBg() {
    // this.bg = this.getRandomElementsFromArray(this.bgs, 1)[0];
  }

  getRandomElementsFromArray(arr, n) {
    let result = new Array(n),
      len = arr.length,
      taken = new Array(len);

    if (n > len) {
      throw new RangeError('getRandom: more elements taken than available');
    }

    while (n--) {
      const x = Math.floor(Math.random() * len);
      result[n] = arr[x in taken ? taken[x] : x];
      taken[x] = --len in taken ? taken[len] : len;
    }
    return result;
  }
}
