import { Component, OnInit } from '@angular/core';

import { DataService } from '../../services/data/data.service';
import * as _ from 'lodash';
import { RoomsService } from '../../services/rooms/rooms.service';
import { IRoom } from '../../services/rooms/interfaces/room-interface.service';
import { NotificationsService } from 'angular2-notifications';
import { Router } from '@angular/router';
import { QuestionsComponent } from '../game/questions/questions.component';
import { QuestionsService } from '../../services/questions/questions.service';
import { IPlayer } from '../../services/rooms/interfaces/player-interface.service';
import { AdsService } from '../../services/ads/ads.service';

declare let ga: Function;

@Component({
  selector: 'app-end',
  templateUrl: './end.component.html',
  styleUrls: ['./end.component.scss']
})
export class EndComponent implements OnInit {

  public playerStore = localStorage.getItem('playerStore');

  public cards: object = { 'like': [], 'try': [] };

  public RoundQuestionsEnd: Array<string> = [];

  public newgame = true;
  public resetInProgress = false;
  public objectKeys = Object.keys;

  constructor(
    private _dataService: DataService,
    private _roomsService: RoomsService,
    private _questionsService: QuestionsService,
    private _notificationsService: NotificationsService,
    private _adsService: AdsService,
    private _router: Router,
  ) {
    document.body.className = 'end';
  }

  ngOnInit() {
    this.playerStore = (this.playerStore != null ? JSON.parse(this.playerStore) : { 'id': '', 'player': '' });
    // load up game
    // check for running game
    if (this.playerStore['id'] !== '') {
      this._roomsService.get(this.playerStore['id']).then((room: IRoom) => {
        const roundCards = _.values(room.cards);

        // sort cards into one list for know you like and presumtuations:
        roundCards.forEach(cards => {
          cards.forEach(card => {
            if (card.questionId === 1) {
              if (this.checkForCard(card) && this.checkForCard(card, 'try')) {
                this.cards['try'].push(card);
              }
            } else {
              if (this.checkForCard(card)) {
                this.cards['like'].push(card);
              }
            }
          });
        });
        // sort by how much the matchs like something
        this.cards['try'].sort((a, b) =>
          (a['player1'] + a['player2'] < b['player1'] + b['player2'] ? 1 :
          (a['player1'] + a['player2'] > b['player1'] + b['player2'] ? -1 : // sort by how much the match
          (a['title'] < b['title'] ? -1 : (a['title'] > b['title'] ? 1 : 0)))) ); // sort by title
        this.cards['like'].sort((a, b) =>
          (a['player1'] + a['player2'] < b['player1'] + b['player2'] ? 1 :
          (a['player1'] + a['player2'] > b['player1'] + b['player2'] ? -1 : // sort by how much the match
          (a['title'] < b['title'] ? -1 : (a['title'] > b['title'] ? 1 : 0)))) ); // sort by title

        this._roomsService.getInRealTime(this.playerStore['id']);

        // finish game so you can't continue
        this._dataService.room.finished = true;
        this._roomsService.update(this._dataService.room, this.playerStore['id']);
      }).catch((err) => {
        console.log('ERROR:', err);

        // no jumping in on games
        if (typeof this.room.connected === 'undefined') {
          this._notificationsService.error('Error:', 'Room id not found, please check it and try again');
          this._router.navigate(['/join']);
        }
      });
    } else {
      this._notificationsService.error('Error:', 'Room id not found, please check it and try again');
      this._router.navigate(['/join']);
    }

    // round questions
    this.RoundQuestionsEnd = this._dataService.RoundQuestionsEnd;
  }

  get player1() {
    return typeof this._dataService.players[0] != 'undefined' ? this._dataService.players[0] : {} as IPlayer;
  }

  get player2() {
    return typeof this._dataService.players[1] != 'undefined' ? this._dataService.players[1] : {} as IPlayer;
  }

  get room() {
    return this._dataService.room;
  }

  get scoreP1() {
    if (typeof this._dataService.room != 'undefined' && typeof this._dataService.room.roundScoresPlayer1 != 'undefined') {
      return this._dataService.room.roundScoresPlayer1.reduce((a, b) => a + b, 0);
    }
    return 0;
  }

  get scoreP2() {
    if (typeof this._dataService.room != 'undefined' && typeof this._dataService.room.roundScoresPlayer2 != 'undefined') {
      return this._dataService.room.roundScoresPlayer2.reduce((a, b) => a + b, 0);
    }
    return 0;
  }

  get score() {
    return this._dataService.room.roundScores.reduce((a, b) => a + b, 0);
  }

  get newGameStarted() {
    if (this._dataService.room.finished == false && this.newgame && !this.resetInProgress) {
      this.newgame = false;
      // make sure room is reset
      this._roomsService.get(this.playerStore['id']).then(() => {
        this._notificationsService.create('Success', 'New game has started!');
        this._router.navigate(['/game', { 'c': this.playerStore['id'] }]);
      });
    }
    return this._dataService.room.round < 0;
  }

  checkForCard(card, category: string = 'like') {
    let found = false;
    this.cards[category].forEach(c => {
      if (c.title == card.title) {
        found = true;
      }
    });
    if (found == false) {
      return true;
    }
    return false;
  }

  async anotherGame() {
    if (this.resetInProgress == false) {
      this.resetInProgress = true;

      // clone current game for analytical purposes
      this.room.parent = this.playerStore['id'];
      await this._roomsService.create(this.room);
      // TODO consider copying players and answers over

      // reset values
      this.room.finished = false;
      this.room.parent = '';

      // Delete current answers
      await this._roomsService.getAnswersToDelete(this.playerStore['id']).then(answersToDelete => {
        // console.log('answersToDelete', answersToDelete);
        answersToDelete.forEach(answer => {
          this._roomsService.deleteAnswer(this.playerStore['id'], answer['id']);
        });
      });

      // clear current game and generate new cards
      // GENERATE GAME CARDS
      // get cards for this round
      const pickedQuestions: Array<string> = [];
      const gameCards: object = {};
      let round = 0;
      await this._questionsService.getAll().then((cards: Array<object>) => {
        // go through each round, pulling random cards to build up the whole game
        // limit per round
        this._dataService.escalation_rules[this._dataService.room.combo].forEach(allowed => {
          const cardsToChooseFrom = [];
          // loop all cards
          cards.forEach(item => {
            if (allowed.indexOf(item['category']) !== -1) {
              // question is allowed for combo
              if (item['combo'].length === 0 || item['combo'].indexOf(this._dataService.room.combo) !== -1) {
                // make sure question is unique
                if ( pickedQuestions.indexOf(item['image']) === -1 ) {
                  cardsToChooseFrom.push(item);
                }
              }
            }
          });
          const round_cards = this.getRandomElementsFromArray(cardsToChooseFrom, 5);
          // make sure question is unique
          round_cards.forEach(element => {
            pickedQuestions.push(element['image']);
          });

          gameCards[round] = round_cards;
          round++;
        });

        // force the reset of the room
        this._roomsService.get(this.playerStore['id']);

        // update planData // push user back into the next game
        const roomVO = {} as IRoom;
        roomVO.cards = gameCards;
        roomVO.round = 0;
        roomVO.finished = false;
        roomVO.roundScores = [];
        roomVO.roundScoresPlayer1 = [];
        roomVO.roundScoresPlayer2 = [];
        this._roomsService.update(roomVO, this.playerStore['id']).then(() => {

          const player1 = {} as IPlayer;
          player1.roundscore = 0;
          player1.score = 0;
          player1.lastScore = 0;
          player1.ready = false;
          this._roomsService.playerUpdate(this.playerStore['id'], this._dataService.players[0]['id'], player1).then(() => {

            const player2 = {} as IPlayer;
            player2.roundscore = 0;
            player2.score = 0;
            player2.lastScore = 0;
            player2.ready = false;
            this._roomsService.playerUpdate(this.playerStore['id'], this._dataService.players[1]['id'], player2).then(() => {

              this._notificationsService.create('Success', 'Successfully started a new game!');
              this._router.navigate(['/game', { 'c': this.playerStore['id'] }]);

              // automatically pull other user with this one
            }).catch(err => console.log("player 2 ERROR: ", err));
          }).catch(err => console.log("player 1 ERROR: ", err));
        }).catch(err => console.log("Room ERROR: ", err));
      });
    }
  }

  getRandomElementsFromArray(arr, n) {
    let len = arr.length;
    const result = new Array(n),
      taken = new Array(len);

    if (n > len) {
      throw new RangeError('getRandom: more elements taken than available');
    }

    while (n--) {
      const x = Math.floor(Math.random() * len);
      result[n] = arr[x in taken ? taken[x] : x];
      taken[x] = --len in taken ? taken[len] : len;
    }
    return result;
  }

  // ADS -----------------------------------------------------
  isDefined(val): boolean {
    return typeof val !== 'undefined';
  }

  genAmazonLink(val: string): string {
    return val;
    // return this._adsService.genAmazonLink(val);
  }

  affilateTracking(type: string, item: string) {
    // (<any>window).ga('send', 'event', 'affilate', type, item);
  }
}
