import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FirestoreService} from './service/filestore.service';

@NgModule({
  imports: [
    CommonModule,
  ],
  declarations: [
  ],
  providers: [
    FirestoreService
  ],
  exports: [
  ]
})
export class CoreModule { }
