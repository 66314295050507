import { Injectable } from '@angular/core';
import { IRoom } from '../rooms/interfaces/room-interface.service';
import { IPlayer } from '../rooms/interfaces/player-interface.service';

@Injectable({
  providedIn: 'root'
})
export class DataService {

  // room
  public room: any; // hold the shared room object
  public players: Array<IPlayer> = []; // holds the players
  public lastRound = -1;

  // game
  public score: number = 0; // combined score
  public roundScore: number = 0; // round score

  public scores: object = { 'player1': [], 'player2': [] }; // list of games and scores
  public cards: object = {}; // all possible cards to choose from
  public currentGame: object = {}; // everything needed for the game
  public showScores = false;

  // game rules 0-1
  public RoundQuestions = [
    'I like...',
    'Guess what your partner likes...'
  ];
  public RoundQuestionsEnd = [
    'You both like:',
    'You should try:',

  ];
  public cards_per_round: number = 5;
  public escalation_rules: object = {
    'mf': [
      ['in-the-mood', 'sexual-preferences', 'behaviour'], // round 1
      ['in-the-mood', 'sexual-preferences', 'behaviour', 'foreplay', 'sexual-acts'], // round 2
      ['foreplay', 'dress-up', 'behaviour', 'sexual-acts'], // round 3
      ['foreplay', 'dress-up', 'location', 'sexual-acts'], // round 4
      ['dress-up', 'location', 'oral'], // round 5
      ['dress-up', 'location', 'oral', 'porn-erotica'], // round 6
      ['oral', 'porn-erotica', 'roleplay'], // round 7
      ['porn-erotica', 'roleplay', 'positions'], // round 8
      ['porn-erotica', 'roleplay', 'positions', 'anal'], // round 9
      ['roleplay', 'positions', 'anal', 'Sex-toys'], // round 10
      ['positions', 'anal', 'sex-toys', 'media'], // round 11
      ['anal', 'sex-toys', 'media', 'group'], // round 12
      ['sex-toys', 'media', 'group', 'fetishism', 'BDSM'], // round 13
      ['group', 'fetishism', 'BDSM', 'extreme'], // round 14
      ['fetishism', 'BDSM', 'extreme'], // round 15
    ],
    'ff': [
      ['in-the-mood', 'sexual-preferences', 'behaviour'], // round 1
      ['in-the-mood', 'sexual-preferences', 'behaviour', 'foreplay', 'sexual-acts'], // round 2
      ['foreplay', 'dress-up', 'behaviour', 'sexual-acts'], // round 3
      ['foreplay', 'dress-up', 'location', 'sexual-acts'], // round 4
      ['dress-up', 'location', 'oral'], // round 5
      ['dress-up', 'location', 'oral', 'porn-erotica'], // round 6
      ['oral', 'porn-erotica', 'roleplay'], // round 7
      ['porn-erotica', 'roleplay', 'positions'], // round 8
      ['porn-erotica', 'roleplay', 'positions', 'anal'], // round 9
      ['roleplay', 'positions', 'anal', 'sex-toys'], // round 10
      ['positions', 'anal', 'sex-toys', 'media'], // round 11
      ['anal', 'sex-toys', 'media', 'group'], // round 12
      ['sex-toys', 'media', 'group', 'fetishism', 'BDSM'], // round 13
      ['group', 'fetishism', 'BDSM', 'extreme'], // round 14
      ['fetishism', 'BDSM', 'extreme'], // round 15
    ],
    'mm': [
      ['in-the-mood', 'sexual-preferences', 'behaviour'], // round 1
      ['in-the-mood', 'sexual-preferences', 'behaviour', 'foreplay', 'sexual-acts'], // round 2
      ['foreplay', 'dress-up', 'behaviour', 'sexual-acts'], // round 3
      ['foreplay', 'dress-up', 'location', 'sexual-acts'], // round 4
      ['dress-up', 'location', 'oral'], // round 5
      ['dress-up', 'location', 'oral', 'porn-erotica'], // round 6
      ['oral', 'porn-erotica', 'roleplay'], // round 7
      ['porn-erotica', 'roleplay', 'positions'], // round 8
      ['porn-erotica', 'roleplay', 'positions', 'anal'], // round 9
      ['roleplay', 'positions', 'anal', 'sex-toys'], // round 10
      ['positions', 'anal', 'sex-toys', 'media'], // round 11
      ['anal', 'sex-toys', 'media', 'group'], // round 12
      ['sex-toys', 'media', 'group', 'fetishism', 'BDSM'], // round 13
      ['group', 'fetishism', 'BDSM', 'extreme'], // round 14
      ['fetishism', 'BDSM', 'extreme'], // round 15
    ]
  };

  constructor() { }
}
