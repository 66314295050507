import { Injectable } from '@angular/core';
import { DataService } from '../data/data.service';
import { FirestoreService } from '../../core/service/filestore.service';
import * as _ from 'lodash';
import { take } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class QuestionsService {

  constructor(
    private _dataService: DataService,
    private _firestoreService: FirestoreService
  ) { }

  async getAll(combo: string = 'mf'): Promise<any> {
    return await this._firestoreService.colWithIds$(`questions`)
    .pipe(take(1))
    .toPromise()
    .then((categories) => {
      const cats = [];
       _.values(categories).filter(category => {
        _.values(category).forEach(c => {
          if (typeof c.combo !== 'undefined') {
            if (c.combo.length === 0 || c.combo.indexOf(combo) !== -1) {
              cats.push(c);
            }
          }
        });
      });
      return cats;
    }).catch(err => {
      throw new Error(err);
    });
  }

  getAllByRound() {
    return new Promise((resolve, reject) => {
      this._firestoreService.colWithIds$('questions').subscribe((categories) => {
        // limit by room combo type
        const cats = [];
        categories.forEach(cat => {
          // limit per round
          const allowed = this._dataService.escalation_rules[this._dataService.room.combo][this._dataService.room.round];
          const cate = _.values(cat);

          // TODO remove when sure it's safe too..
          if (typeof cate[0].category === 'undefined') {
            console.log(cate[0]);
          }

          if (allowed.indexOf(cate[0].category) !== -1) {
            cate.forEach(c => {
              if (typeof c.combo === 'undefined') {
                console.log('missing COMBO:', c);
              } else {
                if (c.combo.length === 0 || c.combo.indexOf(this._dataService.room.combo) !== -1) {

                  // sort out the image path
                  // c.image = c.title.replace(/ /g,'_').replace('/','').toLowerCase();

                  cats.push(c);
                }
              }
            });
          }
        });

        this._dataService.cards = cats;
        resolve(cats);
      });
    });
  }
}
