import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-exceeded-limit',
  templateUrl: './exceeded-limit.component.html',
  styleUrls: ['./exceeded-limit.component.scss']
})
export class ExceededLimitComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
