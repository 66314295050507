import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { RoomsService } from '../../services/rooms/rooms.service';
import { DataService } from '../../services/data/data.service';
import { IPlayer } from '../../services/rooms/interfaces/player-interface.service';
import { IRoom } from '../../services/rooms/interfaces/room-interface.service';
import * as _ from 'lodash';
import { NotificationsService } from 'angular2-notifications';

@Component({
  selector: 'app-room-start',
  templateUrl: './room-start.component.html',
  styleUrls: ['./room-start.component.scss']
})
export class RoomStartComponent implements OnInit {
  public playerStore = localStorage.getItem('playerStore');
  public room = {} as IRoom;
  public isWorking: boolean = false;

  public startForm: FormGroup;
  public nickname: FormControl;
  public gender: FormControl;

  constructor(
    private _roomService: RoomsService,
    private _dataService: DataService,
    private _notificationsService: NotificationsService,
    private _router: Router
  ) { }

  ngOnInit() {
    this.playerStore = (this.playerStore != null ? JSON.parse(this.playerStore) : { 'id': '', 'player': '' });
    this._buildForm();

    // check for running game
    if (typeof this.playerStore['id'] !== 'undefined') {
      this._roomService.get(this.playerStore['id'])
        .then((room: IRoom) => {
        this.room = room;

        // reset the cookie if game is closed
        // if(room.finished == true) {
        //   localStorage.setItem('playerStore', JSON.stringify({}))
        // }
      }).catch(err => {
        console.log('Error:', err);
        if (err.message === 'FirebaseError: [code=resource-exhausted]: Quota exceeded.') {
          this._router.navigate(['/overloaded']);
        }

        localStorage.setItem('playerStore', JSON.stringify({}));
      });
    }
  }

  get realtimePlayer1() {
    return typeof this._dataService.players[0] !== 'undefined' ? this._dataService.players[0] : {} as IPlayer;
  }

  get realtimePlayer2() {
    return typeof this._dataService.players[1] !== 'undefined' ? this._dataService.players[1] : {} as IPlayer;
  }

  closeGame() {
    this._roomService.close(this.playerStore['id']).catch(err => {
      if (err.message === 'FirebaseError: [code=resource-exhausted]: Quota exceeded.') {
        this._router.navigate(['/overloaded']);
      }
    });

    // reset storage
    localStorage['id'] = '';
  }

  // FORM ---------------------------------------------
  _buildForm(): void {

    this.nickname = new FormControl('', [Validators.required, Validators.minLength(2)]);
    this.gender = new FormControl('', [Validators.required]);

    this.startForm = new FormGroup({
      'nickname': this.nickname,
      'gender': this.gender,
    });
  }

  async onSubmit($event) {
    this.save();
  }

  async save() {
    this.isWorking = true;
    // New room
    this._dataService.room = {} as IRoom;

    // close old games
    if (this.playerStore['id']) {
      await this._roomService.close(this.playerStore['id']);
      // reset storage
      // localStorage.removeItem('playerStore');
    }

    // create
    try {
      const roomVO = {} as IRoom;
      roomVO.connected = false; // If two users have connected to it
      roomVO.finished = false;
      roomVO.round = 0;
      roomVO.roundScores = [];
      // mark test accounts!
      if (this.nickname.value === 'testing') {
        roomVO.test = true;
      }
      const room = await this._roomService.create(roomVO);

      // player1
      const player1 = {} as IPlayer;
      player1.uid = 'player1';
      player1.nickname = this.nickname.value;
      player1.gender = this.gender.value;
      player1.score = 0;
      player1.ready = false;
      await this._roomService.playerCreate(room.uid, player1);
      // temp player 2
      const player2 = {} as IPlayer;
      player2.uid = 'player2';
      player1.gender = '';
      player2.score = 0;
      player2.ready = false;
      await this._roomService.playerCreate(room.uid, player2);

      // store the user
      localStorage.setItem('playerStore', JSON.stringify({ 'id': room.uid, 'player': 1 }));

      this._router.navigate(['/lobby', room.uid]);
    } catch (err) {
      this._notificationsService.error('Error:', err);
      this.isWorking = false;
    }
  }

}
