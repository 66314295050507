import { Component, OnInit } from '@angular/core';
import { NotificationsService } from 'angular2-notifications';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {

  buyCoffee = false;

  constructor(
    private _notificationsService: NotificationsService
  ) { }

  ngOnInit() {
  }

  thisYear() {
    return new Date().getFullYear();
  }

  copiedAddress(btc: boolean = true) {
    if (btc) {
      this._notificationsService.alert('BTC Address', 'has been copied to the clipboard');
    } else {
      this._notificationsService.alert('Paypal', 'link has been copied to the clipboard');
    }
  }

}
