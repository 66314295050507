import { Component, OnInit } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {

  public options: any = {};

  constructor(
    private router: Router
  ) {
    this.options.position = ['top', 'right'];
    this.options.timeOut = 5000;
    this.options.clickToClose = true;
  }

  ngOnInit() {
    /**
     * Event listener to scroll to the top of the page on route change
     */
    this.router.events.subscribe((evt) => {
      if (!(evt instanceof NavigationEnd)) {
        return;
      }

      window.scrollTo(0, 0);
    });
  }
}
