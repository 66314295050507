import { Component, OnInit } from '@angular/core';

import { IRoom } from '../../../services/rooms/interfaces/room-interface.service';
import { RoomsService } from '../../../services/rooms/rooms.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-game-header',
  templateUrl: './game-header.component.html',
  styleUrls: ['./game-header.component.scss']
})
export class GameHeaderComponent implements OnInit {

  public playerStore = localStorage.getItem('playerStore');

  public room: IRoom;

  constructor(
    private _roomsService: RoomsService,
    private _router: Router
  ) { }

  ngOnInit() {
    this.playerStore = (this.playerStore != null ? JSON.parse(this.playerStore) : {'id': '', 'player': ''});
    // check for running game
    if (this.playerStore['id'] !== '') {
      this._roomsService.get(this.playerStore['id']).then((room: IRoom) => {
        this.room = room;
      }).catch(err => {
        console.log(err.message);
        if (err.message === 'FirebaseError: [code=resource-exhausted]: Quota exceeded.' || err.message === 'Quota exceeded.') {
          this._router.navigate(['/overloaded']);
        }
      });
    }
  }

}
