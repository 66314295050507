import { Component, OnInit } from '@angular/core';
import { DataService } from '../../services/data/data.service';

import { RoomsService } from '../../services/rooms/rooms.service';
import { Router } from '@angular/router';
import { NotificationsService } from 'angular2-notifications';
import { IRoom } from '../../services/rooms/interfaces/room-interface.service';
import { IPlayer } from '../../services/rooms/interfaces/player-interface.service';

@Component({
  selector: 'app-room-lobby',
  templateUrl: './room-lobby.component.html',
  styleUrls: ['./room-lobby.component.scss']
})
export class RoomLobbyComponent implements OnInit {

  public playerStore = localStorage.getItem('playerStore');

  public roomId = '';
  public room: IRoom;

  constructor(
    private _roomService: RoomsService,
    private _dataService: DataService,
    private _router: Router,
    private _notificationsService: NotificationsService
  ) { }

  ngOnInit() {
    if (this.playerStore == null) {
      this._notificationsService.error('Error:', 'Room ID not found. Please try again 1');
      this._router.navigate(['/']);
    }
    this.playerStore = (this.playerStore !== null ? JSON.parse(this.playerStore) : { 'id': '', 'player': '' });

    if (this.playerStore['id'] !== '') {
      this._roomService.get(this.playerStore['id']).then((room: IRoom) => {
        // console.log('loaded room successfully')
        // console.log('store', this.playerStore);
        // console.log('room', room);
        // console.log('players', this._dataService.players);

        if (this._dataService.players[0].nickname !== '' && this._dataService.players[1].nickname !== undefined) {
          // console.log('Both players detected, redirect to game')
          // console.log('player1', this._dataService.players[0].nickname)
          // console.log('player2', this._dataService.players[1].nickname)

          this._router.navigate(['/game']);
        }

        this.room = room;
        this.roomId = this.playerStore['id'];

        // load players into dataService
        // load it in read time for shared play
        this._roomService.getInRealTime(this.playerStore['id']);
        this._roomService.playerGet(this.playerStore['id'], this._dataService.players[0]['id'], 0);
        this._roomService.playerGet(this.playerStore['id'], this._dataService.players[1]['id'], 1);

      }).catch(err => {
        this._notificationsService.error('Error:', err);
        this._router.navigate(['/']);
      });
    } else {
      this._notificationsService.error('Error:', 'Room ID not found. Please try again 3');
      this._router.navigate(['/']);
    }
  }

  get realtimePlayer1() {
    return typeof this._dataService.players[0].nickname !== 'undefined' ? this._dataService.players[0] : {} as IPlayer;
  }

  get realtimePlayer2() {
    return typeof this._dataService.players[1].nickname !== 'undefined' ? this._dataService.players[1] : {} as IPlayer;
  }

  get realtimeRoom() {
    return typeof this._dataService.room !== 'undefined' ? this._dataService.room : {} as IRoom;
  }

  get checkForReadyPlayers() {
    // console.log('plaaaaaaaaaayas', this._dataService.players);
    if (typeof this._dataService.players[0] !== 'undefined' && typeof this._dataService.players[1] !== 'undefined'
      && this._dataService.players[0].nickname !== '' && this._dataService.players[1].nickname !== ''
      && this._dataService.players[1].nickname !== undefined) {
      // console.log('checkForReadyPlayers', true);
      this._router.navigate(['/game', { 'c': this.roomId }]);
      return true;
    }
    // console.log('checkForReadyPlayers', false);
    return false;
  }

  copiedNotification() {
    this._notificationsService.alert('Game code', 'has been copied to the clipboard');
  }

}


