import { Injectable } from '@angular/core';
import {FirestoreService} from '../../core/service/filestore.service';
import { AngularFirestore } from '@angular/fire/firestore';
import { IContact } from './interfaces/contact-interface.service';

@Injectable({
  providedIn: 'root'
})
export class ContactService {

  constructor(
    private _firestoreService: FirestoreService
  ) { }

  async create(category:IContact) {
    return this._firestoreService.add('contact', category);
  }
}
