import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { NotificationsService } from 'angular2-notifications';
import { ContactService } from '../../services/contact/contact.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { IContact } from '../../services/contact/interfaces/contact-interface.service';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss']
})
export class ContactComponent implements OnInit {

  public contactForm: FormGroup;
  public email: FormControl;
  public message: FormControl;
  public isWorking: boolean = false;
  public passedReCapture: boolean = true;

  constructor(
    private _notificationsService: NotificationsService,
    private _contactService: ContactService,
    private http: HttpClient,
  ) { }

  ngOnInit() {
    this._buildForm();
  }

  // FORM ---------------------------------------------
  _buildForm(): void {

    this.email = new FormControl('', [Validators.email]);
    this.message = new FormControl('', [Validators.required]);

    this.contactForm = new FormGroup({
      'email': this.email,
      'message': this.message
    });
  }

  async onSubmit($event) {
    $event.preventDefault();
    if (this.passedReCapture) {
      this.disableForm();

      this.isWorking = true;

      const vo = {} as IContact;
      vo.email = this.email.value;
      vo.message = this.message.value;
      vo.processed = false;

      // update planData
      try {
        this._contactService.create(vo).then(() => {
          this._notificationsService.create('Success', 'Your message has been successfully submitted. We will get back to as soon as we can.');
          this.isWorking = false;
          this.contactForm.reset();
          this.enableForm();
        }).catch(err => {
          this._notificationsService.error('Error', err);
        });
      } catch (err) {
        this.isWorking = false;
        console.log(err);
        this._notificationsService.error('Error:', err.message);
      }
    } else {
      this._notificationsService.error('Error:', 'Sorry, but you have failed to proove your human, please make sure to pass the ReCapture');
      this.isWorking = false;
    }
  }

  // Recapture --------------------------------------------------------------------------------
  getFormUrlEncoded(toConvert) {
    const formBody = [];
    for (const property of toConvert) {
      const encodedKey = encodeURIComponent(property);
      const encodedValue = encodeURIComponent(toConvert[property]);
      formBody.push(encodedKey + '=' + encodedValue);
    }

    return formBody.join('&');
  }

  handleCorrectCaptcha($event) {
    console.log($event);

    const params = {
      token: $event
    };
    this.http.post(
      'https://us-central1-unlockmybox.cloudfunctions.net/recaptureValidate',
      this.getFormUrlEncoded(params), {
        headers: new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' })
      }).toPromise().then(requestResponse => {

        console.log(requestResponse);
        this.passedReCapture = true;

      }).catch(err => console.log(err));

    // TODO: do this from firebase!
    // this.http.post(
    //   'https://www.google.com/recaptcha/api/siteverify',
    //   this.getFormUrlEncoded(params), {
    //     headers: new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' })
    //   }).toPromise().then(requestResponse => {
    //     console.log(requestResponse);

    //     this.passedReCapture = true;
    //   }).catch(err => console.log(err));

    // validate request
    // $response=file_get_contents("https://www.google.com/recaptcha/api/siteverify?secret=".$secretKey."&response=".$captcha."&remoteip=".$ip);
    // $responseKeys = json_decode($response,true);
    // if(intval($responseKeys["success"]) !== 1) {
    //   echo '<h2>You are spammer ! Get the @$%K out</h2>';
    // } else {
    //   echo '<h2>Thanks for posting comment.</h2>';
    // }


  }

  disableForm() {
    this.email.disable();
    this.message.disable();
  }

  enableForm() {
    this.email.enable();
    this.message.enable();
  }

}
